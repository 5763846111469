<template>
  <div>
    <v-row class="mt-4 mb-2">
      <v-col align="center">
        <p class="text-h3 my-16 primary--text font-weight-bold">
          Find Us In These Shops
        </p>
      </v-col>
    </v-row>
    <v-container>
      <div v-for="(stores, type) in storeMap" :key="type">
        <v-row>
          <v-col>
            <div style="display:inline-block;">
              <span class="text-h4">
                {{ type }}
              </span>
              <br>
              <div class="stripes" style="height: 15px"></div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="store in stores" :key="store.id" cols="6" md="4">
            <a :href="store.link" target="_blank">{{ store.name }}</a><br/>
            <span>{{ store.address }}</span><br/>
            <span>{{ store.city }}, {{ store.state }} {{ store.zipcode }}</span><br/>
            <span>{{ store.phone }}</span><br/>
          </v-col>
        </v-row>
        <br>
      </div>

    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  name: "FindUsView",
  data() {
    return {
      allStores: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get("/api/store?size=100")
        .then((response) => {
          this.allStores = response.data.content;
        });
    }
  },
  computed: {
    storeMap() {
      let mapped =  this.allStores.reduce((groups, item) => {
        const group = (groups[item.type.displayName] || []);
        group.push(item);
        groups[item.type.displayName] = group;
        return groups;
      }, {});
      let typeOrderMap = this.typeOrderMap
      return Object.keys(mapped).sort((a, b) => typeOrderMap[a] - typeOrderMap[b]).reduce(
        (obj, key) => {
          obj[key] = mapped[key];
          return obj;
        },
        {}
      );
    },
    typeOrderMap() {
      return this.allStores.reduce(function(map, obj) {
        map[obj.type.displayName] = obj.type.order;
        return map;
      }, {});
    }
  }
};
</script>

<style scoped>

</style>
